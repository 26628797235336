import React from 'react'

const ReservationFormBox = ({children, title}) => {
  return (
    <div className="border-[1px] border-cta-4 bg-bg-1 p-[10px] pb-[0px] rounded-[5px] mb-[10px]">
        <div className="text-[14px] leading-[20px] mb-[10px] font-bold">
            {title}
          </div>
        {children}
    </div>
  )
}

export default ReservationFormBox