/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Image } from "@/components/shared/image/image";
import { IconArrowDown, IconWarning } from "../../components/submission-icons";
import { useIsDesktop, useIsTablet } from "@/hooks/use-is-mobile";
import { PreviewAudioContext } from "./preview-audio";
import { useWindowSize } from "@/hooks/use-window-size";

const bgDetail = {
  background:
    "linear-gradient(0deg, #2C2A29, #2C2A29), linear-gradient(180deg, rgba(255, 255, 255, 0.1) -41.6%, rgba(255, 255, 255, 0) 14.96%)",
};

const PreviewAudioDetail = ({ open, isGallery }) => {
  const {
    setIsShowOfficialImage,
    isShowBandMobile,
    setIsShowBandMobile,
    data,
  } = useContext(PreviewAudioContext);
  const isDSS = process.env.NEXT_PUBLIC_NAME === "djisamsoe";
  const { width } = useWindowSize();
  const isMobile = width <= 640;
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const [isShowMoreSong, setIsShowMoreSong] = useState(false);
  const [isShowMoreBand, setIsShowMoreBand] = useState(false);
  const [allowBandAbout, setAllowBandAbout] = useState(false);

  const isTextOver =
    data?.about_song?.length > 545 || data?.caption?.length > 545;
  const listFieldRequired = ["band_name", "city", "band_genre"];

  useEffect(() => {
    const isFilled = checkField(listFieldRequired);
    setAllowBandAbout(isGallery ? true : isFilled.every((fill) => fill));
  }, [open, data]);

  const checkField = (list) => {
    return list.map((field) =>
      data?.listFieldStatus?.[field] ? !!data[field]?.length : true
    );
  };

  const handleToggle = () => {
    if (isMobile) {
      setIsShowMoreBand(false);
      setIsShowBandMobile(!isShowBandMobile);
    } else setIsShowMoreBand(!isShowMoreBand);
  };

  const isGenreExist = () => {
    return (
      data?.band_genre ||
      data?.band_profile?.genre ||
      data?.sub_genre ||
      data?.band_profile?.subgenre
    );
  };

  const handleGenre = () => {
    const genre = data?.band_genre || data?.band_profile?.genre;
    const sub_genre = data?.sub_genre || data?.band_profile?.subgenre;
    return sub_genre ? `${genre}-${sub_genre}` : genre;
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          key="detail"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.2, duration: 0.3 } }}
          exit={{ opacity: 0 }}
          transition={{ type: "Inertia" }}
          className={clsx(
            "w-full relative max-w-[375px] mx-auto",
            isDesktop && "overflow-hidden h-full",
            isTablet && "h-fit"
          )}
        >
          <div
            className={clsx(
              "w-full h-full flex flex-col gap-[15px]",
              isDesktop && "overflow-y-auto overscroll-contain"
            )}
          >
            {/* BAND ABOUT */}
            {allowBandAbout ? (
              <div
                className={clsx(
                  "relative rounded-[15px]",
                  isShowMoreBand && "p-[10px] pb-0"
                )}
                style={bgDetail}
              >
                <div
                  className={clsx(
                    "relative",
                    !isShowMoreBand && "rounded-[15px] overflow-hidden",
                    !data?.official_press_photo?.length && "min-h-[170px]"
                  )}
                >
                  {data?.official_press_photo?.length ? (
                    <div className="flex h-[375px]">
                      <Image
                        src={data?.official_press_photo[0]?.preview}
                        alt="Official Press"
                        data-testid="Official Press"
                        className={
                          isShowMoreBand ? "rounded-[10px]" : "rounded-[15px]"
                        }
                        onLoad={() => {
                          URL.revokeObjectURL(
                            data?.official_press_photo[0]?.preview
                          );
                        }}
                      />
                    </div>
                  ) : null}

                  {/* OVERLAY */}
                  <button
                    className="absolute inset-0"
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(0, 0, 0, 0) 39.83%, rgba(0, 0, 0, 0.8) 82.12%, #2C2A29 100%)",
                    }}
                    disabled={
                      !isShowMoreBand || !data?.official_press_photo?.length
                    }
                    onClick={() => setIsShowOfficialImage(true)}
                  />

                  {!isShowMoreBand && (
                    <>
                      <div className="absolute top-[20px] left-[15px] text-text-4 font-bold font-font-family-7">
                        Tentang Penyanyi
                      </div>
                      <div className="absolute bottom-[15px] left-[15px] right-[15px] flex flex-col gap-[15px]">
                        <div className="flex items-center gap-[11px]">
                          {data?.band_logo?.length ? (
                            <div className="w-[32px] h-[32px] rounded-full overflow-hidden">
                              <Image
                                src={data?.band_logo[0]?.preview}
                                alt="Logo Band"
                                data-testid="Logo Band"
                                onLoad={() => {
                                  URL.revokeObjectURL(
                                    data?.band_logo[0]?.preview
                                  );
                                }}
                              />
                            </div>
                          ) : null}
                          <p className="text-sm text-text-4 font-bold font-font-family-7">
                            {data?.band_name || data?.band_profile?.band_name}
                          </p>
                        </div>
                        <button
                          type="button"
                          className="flex items-center gap-[10px]"
                          onClick={() => handleToggle()}
                        >
                          <p className="text-start text-sm text-text-4 whitespace-pre-line line-clamp-[3]">
                            {data?.band_biography ||
                              data?.band_profile?.biography}
                          </p>
                          <div className="-rotate-90">
                            <IconArrowDown className="stroke-icon-4" />
                          </div>
                        </button>
                        <div className="flex flex-wrap items-center gap-[10px] text-text-4">
                          <p className="text-sm font-normal capitalize">
                            {data?.city?.toLowerCase() ||
                              data?.band_profile?.city?.toLowerCase()}
                          </p>
                          {isGenreExist() && (
                            <div className="py-[6px] px-[15px] bg-black rounded-full">
                              <p className="text-xs capitalize">
                                {handleGenre()}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* BAND ABOUT -> CONTENT */}
                {isShowMoreBand && (
                  <div className="relative -mt-[90px] z-10 px-[10px] pb-[30px]">
                    <div className="mb-[30px] flex items-center gap-[15px]">
                      {data?.band_logo?.length ? (
                        <div className="w-[72px] h-[72px] rounded-full overflow-hidden">
                          <Image
                            src={data?.band_logo[0]?.preview}
                            alt="Logo Band"
                            data-testid="Logo Band"
                            onLoad={() => {
                              URL.revokeObjectURL(data?.band_logo[0]?.preview);
                            }}
                          />
                        </div>
                      ) : null}
                      <div className="flex flex-col gap-[5px] text-text-4">
                        <p className="text-xl font-black font-font-family-8">
                          {data?.band_name || data?.band_profile?.band_name}
                        </p>
                        <p className="text-sm font-normal capitalize">
                          {data?.city?.toLowerCase()}
                        </p>
                      </div>
                    </div>

                    {(data?.band_biography ||
                      data?.band_profile?.biography) && (
                      <p className="mb-[40px] text-sm text-text-4 opacity-85 whitespace-pre-line">
                        {data?.band_biography || data?.band_profile?.biography}
                      </p>
                    )}

                    <div className="flex flex-col gap-[40px]">
                      {isGenreExist() && (
                        <div>
                          <span className="block mb-[10px] text-[10px] text-text-3 tracking-[1px]">
                            GENRE
                          </span>
                          <p className="text-sm text-text-4 font-bold font-font-family-7 capitalize">
                            {handleGenre()}
                          </p>
                        </div>
                      )}

                      {/* SHOW LESS */}
                      <button
                        className={
                          "w-fit self-end text-text-4 text-sm cursor-pointer font-medium font-font-family-6 opacity-50 hover:opacity-100"
                        }
                        onClick={() => handleToggle()}
                      >
                        Tampilkan sedikit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="px-[10px] py-[15px] rounded-[15px] flex gap-[10px]"
                style={bgDetail}
              >
                <div className="min-w-[5px] bg-[#FF9F0A] rounded-[5px]" />
                <div className="flex items-center gap-[10px]">
                  <div>
                    <IconWarning />
                  </div>
                  <div className="flex flex-col text-sm">
                    <p className="font-bold font-font-family-7 text-text-4">
                      Warning
                    </p>
                    <div className="font-medium font-font-family-6 text-text-3">
                      <p>
                        {isDSS ? "Kamu belum" : "Lo belom"} mengisi profil band{" "}
                        {isDSS ? "kamu" : "lo"}.
                      </p>
                      <p>
                        Kembali dan isi bagian ini untuk bisa submit karya{" "}
                        {isDSS ? "kamu" : "lo"}!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* SONG ABOUT */}
            {(data?.about_song || data?.caption) && (
              <div
                className="p-[20px] rounded-[15px] flex flex-col gap-[10px]"
                style={bgDetail}
              >
                <p className="text-base font-bold font-font-family-7 text-text-4">
                  Tentang Lagu Ini
                </p>
                <div className="flex flex-col">
                  <p
                    className={clsx(
                      "relative overflow-hidden text-sm whitespace-pre-line",
                      isShowMoreSong
                        ? "max-h-none"
                        : "max-h-[280px] line-clamp-[14]"
                    )}
                    style={{ color: "rgba(255, 255, 255, 0.75)" }}
                  >
                    {data?.about_song || data?.caption}
                  </p>
                  {isTextOver && (
                    <button
                      className={clsx(
                        "w-fit self-end text-text-4 text-sm cursor-pointer",
                        isShowMoreSong
                          ? "font-medium font-font-family-6 opacity-50 hover:opacity-100"
                          : "font-bold font-font-family-7"
                      )}
                      onClick={() => setIsShowMoreSong(!isShowMoreSong)}
                    >
                      {isShowMoreSong ? "Tampilkan sedikit" : "Selengkapnya"}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
PreviewAudioDetail.propTypes = {
  open: PropTypes.bool,
  isGallery: PropTypes.bool,
};

export default PreviewAudioDetail;
