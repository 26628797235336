"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"
import { formatedDateTime } from "@/helper/utils"
import { useIsMobile } from "@/hooks/use-is-mobile"
import Link from "@/components/shared/link/link"
import { useDatalayer } from "@/hooks/use-datalayer"
import PropTypes from "prop-types"
import { useRouter, useSearchParams } from "next/navigation"
import Rsvp from "./rsvp";

export default function EventCard({
  uuid,
  title,
  location,
  date,
  image_url,
  time,
  desktop_image_url,
  index,
  selectedCategory,
  is_rsvp
}) {
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const searchParams = useSearchParams()
  const router = useRouter()

  const onClickDatalayer = (name, index, selectedCategory) => {
    datalayer.push({
      event: "general_event",
      event_name: "click_event_list",
      feature: "event & hop list",
      content_category: selectedCategory
        ? searchParams.get("event")
        : "Next Closest", // e.g next closest
      content_name: name, // e.g event title x subtitle in collaboration with perspiciatis unde omnis
      content_position: index, // e.g 1
    })
  }
  return (
    <div
    role="button"
    tabIndex={0}
      data-testid="event-card-link"
      className="w-[100%] text-initial"
      // href={`/events/${uuid}`}
      onClick={(e) => {
        e.stopPropagation()
        router.push(`/events/${uuid}`)
        onClickDatalayer(title, index, selectedCategory)
      }}
    >
      <div className="flex gap-[10px] p-[5px] md:p-0 justify-between items-start w-[100%] mx-auto bg-bg-3">
        <div className="grow-[1]">
          <div
            data-testid="event-card-date"
            className="text-[12px] text-text-1 font-bold font-font-family-7 leading-[13px] md:leading-[16px] tracking-[0.25px] md:tracking-[0.12px] mb-[10px] md:mb-[15px] uppercase"
          >
            {formatedDateTime(date, time)} WIB
          </div>
          <div
            data-testid="event-card-title"
            className="min-h-[66px] md:min-h-[54px] text-text-2 text-[16px] md:text-[20px] leading-[22px] md:leading-[27px] font-bold font-font-family-7 mb-[10px] md:mb-[15px] md:col=start-1 md:row-start-2 md:mr-[54px] md:max-w-none capitalize"
          >
            {title}
          </div>
          <div
            data-testid="event-card-location"
            className="text-[12px] text-text-2 md:text-[16px] font-medium font-font-family-6 tracking-[0.25px] md:tracking-[0.32px] md:leading-[22px] col-start-1 row-start-3 md:mb-[10px] mr-[10px] md:mr-0 capitalize"
          >
            {location}
          </div>
          {is_rsvp? <Rsvp uuid={uuid} className="right-0 hidden"/>:null}
        </div>
        <div className="flex flex-col">
          <div className="w-[134px] md:max-w-[165px] h-[100px] md:min-h-[124px] bg-[#D9D9D9] rounded-[10px] row-start-1 col-start-2 row-span-2 md:row-span-4 relative md:self-center overflow-hidden">
            <Image
              data-testid="event-card-image"
              className="absolute"
              alt={title}
              fill
              style={{ objectFit: "cover", objectPosition: "bottom" }}
              src={isMobile ? image_url : desktop_image_url}
            />
          </div>
          <div className="relative min-h-[30px] w-[100%]">
          {is_rsvp? <Rsvp uuid={uuid} className="right-0 lg:min-w-[72px] lg:hidden"/>:null}
          </div>
        </div>
        <div className="max-w-[134px] min-h-[100px] bg-[#D9D9D9] rounded-[10px] row-span-2 lg:row-span-4 relative overflow-hidden">
          <Image
            className="absolute"
            alt={title}
            style={{ objectFit: "cover", objectPosition: "bottom" }}
            src={isMobile ? image_url : desktop_image_url}
          />
        </div>
      </div>
    </div>
  )
}

EventCard.propTypes = {
  uuid: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  image_url: PropTypes.string,
  time: PropTypes.string,
  desktop_image_url: PropTypes.string,
  index: PropTypes.number,
  // selectedCategory: PropTypes.string,
}
