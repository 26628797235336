"use client"
import React, { Suspense, useState } from "react"
import PropTypes from "prop-types"
import DetailBanner from "../shared/detail-sections/detail-banner/detail-banner"
import DetailHeader from "../shared/detail-sections/detail-header/detail-header"
import DetailDescription from "../shared/detail-sections/detail-description/detail-description"
import DetailTnC from "../shared/detail-sections/detail-tnc/detail-tnc"
import ButtonAction from "../shared/button-action/button-action"
import { formatedDate, formatedTime } from "@/helper/utils"
import { useIsMobile } from "@/hooks/use-is-mobile"
import Link from "next/link"
import { useRouter } from "next/navigation"

function EventDetailFallback() {
  return <div>Fallback</div>
}

export function SuspendedEventDetail({
  bg_img_url,
  data,
  date_img_url,
  href,
  location_img_url,
  name,
  params,
  reserve_btn_label,
  searchParams,
  time_img_url,
}) {
  const [dataDetail, setDataDetail] = useState(data)
  const {
    uuid,
    brand_uuid,
    event_title,
    event_date,
    start_time,
    end_time,
    location_desc,
    latitude,
    longitude,
    desc,
    tnc,
    create_date,
    update_date,
    status,
    mobile_image_url,
    desktop_image_url,
    is_rsvp,
  } = dataDetail
  const formattedStartTime = formatedTime(event_date, start_time, false)
  const formattedEndTime = formatedTime(event_date, end_time, false)
  const allTime = `${formattedStartTime} - ${formattedEndTime} WIB`
  const formattedDate = formatedDate(event_date, start_time)
  const router = useRouter()

  const handleToReservation = (e) => {
    e.stopPropagation()
    router.push(`/event/reservation/br?uuid=${uuid}`)
  }

  const isMobile = useIsMobile()

  return (
    <div className="relative">
      <div className="lg:grid lg:grid-cols-[1fr_401px] lg:gap-[30px_15px]">
        {/* {/* <FAB/> */}
        <DetailBanner image={isMobile ? mobile_image_url : desktop_image_url} />
        <DetailHeader
          {...{
            lat: latitude,
            lng: longitude,
            category: "Event",
            title: event_title,
            date: formattedDate,
            time: allTime,
            location: location_desc,
            dateImg: date_img_url,
            timeImg: time_img_url,
            locationImg: location_img_url,
            uuid,
            reserve_btn_label,
            is_rsvp,
          }}
        />
        <DetailDescription
          className="col-start-1 row-start-2 lg:mb-[10px]"
          description={desc}
        />
        <DetailTnC
          className="col-start-1 row-start-3 text-wrap break-all"
          tnc={tnc}
        />
        {is_rsvp ? (
          <div
            style={{
              background:
                "linear-gradient(180deg,rgba(255,255,255,0), var(--background_3) 50%)",
            }}
            className="fixed z-[19] bottom-[56px] left-2/4 -translate-x-1/2 w-full px-[10px] pt-[80px] pb-[20px] lg:hidden"
          >
            <ButtonAction
              onClick={(e) => handleToReservation(e)}
              intent={"primary"}
            >
              {reserve_btn_label}
            </ButtonAction>
          </div>
        ) : null}
      </div>
    </div>
  )
}

SuspendedEventDetail.propTypes = {
  bg_img_url: PropTypes.string,
  data: PropTypes.object,
  date_img_url: PropTypes.string,
  href: PropTypes.string,
  location_img_url: PropTypes.string,
  name: PropTypes.string,
  params: PropTypes.object,
  reserve_btn_label: PropTypes.string,
  searchParams: PropTypes.object,
  time_img_url: PropTypes.string,
}

export function EventDetail(props) {
  return (
    <Suspense fallback={<EventDetailFallback />}>
      <SuspendedEventDetail {...props} />
    </Suspense>
  )
}

EventDetail.propTypes = {
  bg_img_url: PropTypes.string,
  data: PropTypes.object,
  date_img_url: PropTypes.string,
  href: PropTypes.string,
  location_img_url: PropTypes.string,
  name: PropTypes.string,
  params: PropTypes.object,
  reserve_btn_label: PropTypes.string,
  searchParams: PropTypes.object,
  time_img_url: PropTypes.string,
}
