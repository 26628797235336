/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { RemoveScroll } from "react-remove-scroll";
import { PreviewAudioContext } from "./preview-audio";
import { Image } from "@/components/shared/image/image";
import clsx from "clsx";
import { IconClose } from "../../components/submission-icons";
import { AnimatePresence, motion } from "framer-motion";

// COMPONENT FOR MOBILE ONLY
const PreviewBandAbout = () => {
  const {
    isShowBandMobile: open,
    setIsShowBandMobile,
    isShowOfficialImage,
    setIsShowOfficialImage,
    data,
  } = useContext(PreviewAudioContext);

  const handleGenre = () => {
    const genre = data?.band_genre || data?.band_profile?.genre;
    const sub_genre = data?.sub_genre || data?.band_profile?.subgenre;
    return sub_genre ? `${genre}-${sub_genre}` : genre;
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          key="band-about"
          initial={{ x: "100%" }}
          animate={{ x: 0, transition: { delay: 0.2, duration: 0.3 } }}
          exit={{ x: "100%" }}
          transition={{ type: "Inertia" }}
          className="fixed inset-0 z-[20] bg-black"
        >
          <RemoveScroll className="w-full h-[calc(100dvh-56px)]">
            <div
              className={clsx(
                "w-full h-full p-[10px] overflow-hidden",
                !isShowOfficialImage && "overflow-y-auto"
              )}
            >
              <div
                className={clsx(
                  "relative",
                  !data?.official_press_photo?.length && "min-h-[200px]"
                )}
              >
                {data?.official_press_photo?.length ? (
                  <Image
                    src={data?.official_press_photo[0]?.preview}
                    alt="Official Press"
                    data-testid="Official Press"
                    className="rounded-[10px]"
                    onLoad={() => {
                      URL.revokeObjectURL(
                        data?.official_press_photo[0]?.preview
                      );
                    }}
                  />
                ) : null}

                {/* OVERLAY */}
                <button
                  className="absolute inset-0"
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0) 39.83%, rgba(0, 0, 0, 0.8) 82.12%, #000000 100%)",
                  }}
                  disabled={!data?.official_press_photo?.length}
                  onClick={() => setIsShowOfficialImage(true)}
                />

                <button
                  className="absolute top-[5px] right-[5px] p-[6px] text-white"
                  onClick={() => setIsShowBandMobile(false)}
                >
                  <IconClose
                    width={36}
                    height={36}
                    className={"stroke-icon-4"}
                  />
                </button>
              </div>

              <div className="relative -mt-[90px] z-10 px-[10px] pb-[30px]">
                <div className="mb-[30px] flex items-center gap-[15px]">
                  {data?.band_logo?.length ? (
                    <div className="w-[72px] rounded-full overflow-hidden">
                      <Image
                        src={data?.band_logo[0]?.preview}
                        alt="Logo Band"
                        data-testid="Logo Band"
                        onLoad={() => {
                          URL.revokeObjectURL(data?.band_logo[0]?.preview);
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="flex flex-col gap-[5px] text-text-4">
                    <p className="text-xl font-black font-font-family-8">
                      {data?.band_name || data?.band_profile?.band_name}
                    </p>
                    <p className="text-sm font-normal capitalize">
                      {data?.city?.toLowerCase()}
                    </p>
                  </div>
                </div>

                {(data?.band_biography || data?.band_profile?.biography) && (
                  <p className="mb-[40px] text-sm text-text-4 opacity-85 whitespace-pre-line">
                    {data?.band_biography || data?.band_profile?.biography}
                  </p>
                )}

                <div className="flex flex-col gap-[40px]">
                  <div>
                    <span className="block mb-[10px] text-[10px] text-text-3 tracking-[1px]">
                      GENRE
                    </span>
                    <p className="text-sm text-text-4 font-bold font-font-family-7 capitalize">
                      {handleGenre()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </RemoveScroll>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default PreviewBandAbout;
