import React from "react"

const ReservationCardBottomInfo = ({ label, value }) => {
  return (
    <div className="mb-[15px] text-text-2">
      <div className="text-[14px] leading-[20px] font-[400] mb-[2px]">
        {label}
      </div>
      <div className="text-[16px] leading-[24px] font-[500]">{value}</div>
    </div>
  )
}

export default ReservationCardBottomInfo
