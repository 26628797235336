"use client";
import React, { useEffect, useState } from "react";
import BackBanner from "../shared/back-banner";
import ReservationForm from "./reservation-form";
import ReservationConfirmation from "./reservation-confirmation";
import { reservation } from "@/app/(app)/reservation/reservation";
import { ClientApiList } from "@/lib/api/client-service";
import { useRouter, useSearchParams } from "next/navigation";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { Spinner } from "../shared/spinner";

const ReservationWrapper = () => {
  const { replace } = useRouter();
  const [data, setData] = useState(null);
  const searchParams = useSearchParams();
  const isMobile = useIsMobile();

  const handleGetDetail = async () => {
    try {
      const { data } = await ClientApiList.getReservationForm({
        event_uuid: searchParams.get("uuid"),
      });
      if (data.data.data.current_status === "summary") {
        replace(
          "/event/reservation/summary/br?uuid=" + searchParams.get("uuid")
        );
      }
      if (data.data.data.current_status === "verify") {
        replace("/event/reservation/otp/br?uuid=" + searchParams.get("uuid"));
      }
      setData(data.data.data);
    } catch (err) {
      console.log("[Error]", err);
    }
  };

  useEffect(() => {
    handleGetDetail();
  }, []);

  if (!data) return <Spinner />;

  return (
    <>
      <BackBanner
        bgImg={
          isMobile
            ? data?.detail?.rsvp_mobile_image
            : data?.detail?.rsvp_desktop_image
        }
      />
      <ReservationForm data={data} reservation={reservation} />
      <ReservationConfirmation />
    </>
  );
};

export default ReservationWrapper;
