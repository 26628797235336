import React from "react"
import PopupAction from "../shared/popup-action/popup-action"
import { Image } from "../shared/image/image"
import ButtonAction from "../shared/button-action/button-action"

const ReservationConfirmation = ({ isOpen, handleSubmit, handleIsOpen }) => {
  console.log("isOpen", isOpen)
  const handleClose = () => {
    handleIsOpen(false)
  }

  return (
    <PopupAction
      isShowing={isOpen}
      href={`#`}
      onClickBtnX={() => handleClose}
      zIndex="z-[20]"
    >
      <div className="max-w-[304px]">
        <div className="absolute rounded-[10px] overflow-hidden inset-0 z-[-1] "></div>
        <div className="absolute rounded-[10px] border-[3px] border-cta-3 border-dotted inset-[10px] z-[-1]"></div>
        <div className="min-w-[304px] min-h-[177px] bg-bg-1 rounded-[10px] overflow-hidden relative mb-[20px]">
          <Image
            data-testid="popup-image"
            alt="cover"
            fill
            style={{ objectFit: "cover", objectPosition: "center" }}
            className="absolute inset-0"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/reservation/confirmation.png`}
          />
        </div>
        <div
          data-testid="popup-title"
          className="text-[20px] font-black font-font-family-8 leading-[28px] mb-[10px] text-text-2"
        >
          Konfirmasi RSVP
        </div>
        <div
          data-testid="popup-description"
          className="text-[16px] text-text-2 md:text-[16px] font-medium font-font-family-6 mb-[20px] leading-[24px] md:leading-[25px]"
        >
          Pastikan data yang akan lo kirim udah benar. Lo ga bisa <i>ngedit</i>{" "}
          ulang data yang udah lo kirim.
        </div>
        <div className="flex items-center gap-[5px]">
          <ButtonAction
            onClick={() => {
              handleClose()
            }}
            className=" mb-[10px]"
            intent="secondary"
          >
            Cek Lagi
          </ButtonAction>
          <ButtonAction
            onClick={() => {handleSubmit()}}
            className=" mb-[10px]"
            intent="primary"
          >
            Kirim
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}

export default ReservationConfirmation
