"use client";
import React from "react";
import { Image } from "@/components/shared/image/image";
import PropTypes from "prop-types";
import { cn } from "@/lib/utils";
export default function DetailBanner({ image, className }) {
  return (
    <div className={cn("p-[10px] relative lg:p-0 min-h-[282px] bg-transparent relative lg:rounded-[15px] lg:min-h-[412px] overflow-hidden", className)}>
      <Image
        data-testid="detail-banner-img"
        alt="cover"
        fill
        style={{ objectFit: "cover" }}
        src={image}
        className="absolute h-[100%] w-[100%] bg-bg-5 inset-0"
      />
    </div>
  );
}

DetailBanner.propTypes = {
  image: PropTypes.string,
};
