"use client"
import React, { useEffect, useState } from "react"
import ButtonBack from "../shared/button-back/button-back"
import PropTypes from "prop-types"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { usePathname } from "next/navigation"
import { useRedirect } from "@/hooks/use-redirect"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useScrollTracker } from "react-scroll-tracker"
import { Spinner } from "../shared/spinner";

export function SpecialPageLayout({ children }) {
  const { isLogin, isLoading } = useIsLogin()
  // const datalayer = useDatalayer()
  const pathname = usePathname()
  const isMobile = useIsMobile()
  const datalayer = useDatalayer()
  const { scrollY } = useScrollTracker()

  const [firstScroll, setFirstScroll] = useState(false)
  const [secondScroll, setSecondScroll] = useState(false)
  const [thirdScroll, setThirdScroll] = useState(false)
  const [finishScroll, setFinishScroll] = useState(false)

  const bottom =
    typeof window !== "undefined" &&
    window.innerHeight + window.scrollY >= document.body.offsetHeight

  const scrollDepth = (progress) => {
    datalayer.push({
      event: "general_event",
      event_name: "scroll_depth_non_article",
      feature: "hero engagement page",
      page_path: pathname,
      content_progress: progress,
      engagement_type: "interaction", // e.g., home
      campaign_name: pathname.split("/")[1].split("-").join(" "), // e.g all
    })
  }

  useEffect(() => {
    if (((scrollY >= 25 && scrollY < 50) || bottom) && !firstScroll) {
      scrollDepth("25%")
      setFirstScroll(true)
    } else if (
      ((scrollY >= 50 && scrollY < 75 && firstScroll) ||
        (bottom && firstScroll)) &&
      !secondScroll
    ) {
      scrollDepth("50%")
      setSecondScroll(true)
    } else if (
      ((scrollY >= 75 && scrollY < 100 && secondScroll) ||
        (bottom && secondScroll)) &&
      !thirdScroll
    ) {
      scrollDepth("75%")
      setThirdScroll(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [scrollY, firstScroll, secondScroll, thirdScroll])

  useEffect(() => {
    if (bottom && !finishScroll && thirdScroll) {
      scrollDepth("100%")
      setFinishScroll(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [bottom, finishScroll, thirdScroll])

  useShowLoginPopup({
    showWhen: pathname.includes("/br") && !isLogin && !isLoading,
    closePath: `${process.env.NEXT_PUBLIC_META_URL}/${pathname.slice(0, -3)}/ub`,
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
  })
  // const router = useRouter()
  useRedirect({
    redirectWhen: !pathname.includes("/br") && isLogin && !isLoading,
    path: `/${pathname.slice(0, -3)}/br`,
    withHash: true,
    withQuery: true,
  })

  /* scroll to hash */
  useEffect(() => {
    let t
    const hash = pathname.split("#")[1]

    if (!isLoading && hash) {
      const el = document?.getElementById(pathname.split("#")[1])

      if (el)
        t = setTimeout(() => {
          const offsetHeader = isMobile ? 58 : 72
          const offset = el?.offsetTop - offsetHeader

          window.scrollTo({
            top: offset,
            behavior: "smooth",
          })
        }, 200)
    }

    return () => {
      clearTimeout(t)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, pathname])

  const page = typeof window !== "undefined" && document.body.offsetHeight > 0

  return (
    <div className="relative pt-[231px] md:pt-[296px] px-[10px] lg:p-[15px] lg:grid lg:grid-cols-[1fr] lg:grid-rows-[minmax(520px,_1fr)] lg:gap-[10px_0px] lg:items-stretch mt-[58px] lg:mt-[72px]">
      <ButtonBack className="lg:hidden" />
      {/* <div className="hidden lg:block">&nbsp;</div> */}
      {/* <div className="absolute rounded-b-[10px] top-[0] left-0 right-0 h-[738px] w-[100%] bg-[#646464] z-1"></div> */}
      {page ?  children : <Spinner/>}
    </div>
  )
}

SpecialPageLayout.propTypes = {
  children: PropTypes.object,
}
