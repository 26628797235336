"use client"
import React from "react"
import PropTypes from "prop-types"
import { usePathname, useRouter } from "next/navigation"
import PopupAction from "../shared/popup-action/popup-action"
import { Image } from "../shared/image/image"
import ButtonAction from "../shared/button-action/button-action"

export default function PopupLink({
  isOpen,
  setIsOpen,
  handleRequestOTP,
  event_uuid,
}) {
  const pathname = usePathname()
  const { replace } = useRouter()

  const handleReqOTP = async () => {
    await handleRequestOTP()
    setIsOpen(false)
    replace(`/event/reservation/otp/br?uuid=` + event_uuid)
  }

  const user = process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"
  return (
    <PopupAction isShowing={isOpen} href={pathname} zIndex="z-[20]">
      <div className="max-w-[304px]">
        <div className="rounded-[10px] w-[304px] h-[177px] bg-bg-1 mb-[20px] relative">
          <Image
            className="absolute inset-0"
            alt="delete"
            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/profile-edit/otp.png`}
          />
        </div>
        <div
          data-testid="popup-otp-title"
          className="text-[20px] font-black font-font-family-8 mb-[10px] leading-[28px] text-text-2 max-w-[200px] mx-auto"
          // style={{ background: 'var(--text_2)', WebkitTextFillColor: 'transparent' }}
        >
          Verifikasi Akun Lo Buat RSVP Event Ini
        </div>
        <div
          data-testid="popup-otp-description"
          className="text-[16px] leading-[24px] mb-[20px] text-center text-text-2"
          // style={{ background: 'var(--text_2)', WebkitTextFillColor: 'transparent' }}
        >
          Kami akan mengirimkan link verifikasi ke email yang lo daftarkan. Klik
          linknya untuk menyelesaikan verifikasi akun lo.
        </div>

        <div className="flex items-center justify-between gap-[10px]">
          <ButtonAction
            className="py-[15px] px-[20px] leading-[20px]"
            intent="primary"
            onClick={() => handleReqOTP()}
          >
            Mengerti
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}

PopupLink.propTypes = {
  isOpen: PropTypes.bool,
  setStep: PropTypes.func,
  setIsOpen: PropTypes.func,
  isSms: PropTypes.bool,
  handleRequestOTP: PropTypes.func,
}
