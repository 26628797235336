"use client"
import React, { useState, useEffect, Suspense, useRef } from "react"
import { useSearchParams } from "next/navigation"
import "swiper/css"
import EventCategories from "../events/event-categories/event-categories"
import EventList from "../events/event-list/event-list"
import LoadMoreBtn from "../events/event-list/load-more-btn"
import { ClientApiList } from "@/lib/api/client-service"
import PropTypes from "prop-types"
import Styles from "./event-list-calendar.module.css"
import { cn } from "@/lib/utils"

// closest | week | month | year
const categories = [
  {
    id: 1,
    label: "Next Closest",
    value: "closest",
  },
  {
    id: 2,
    label: "This Week",
    value: "week",
  },
  {
    id: 3,
    label: "This Month",
    value: "month",
  },
  {
    id: 4,
    label: "This Year",
    value: "year",
  },
]

function EventListCalendarFallback() {
  return <p>Loading</p>
}

function SuspendedEventListCalendar({
  load_more_btn_label,
  data: dataProps,
  title,
  // ...props
}) {
  const searchParams = useSearchParams()
  const currentPage = searchParams.get("event-page") || 0
  const [listEvents, setListEvents] = useState([...dataProps.items])
  const [pagingData, setPagingData] = useState({ ...dataProps.paging })
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [pageTitle, setPageTitle] = useState(title)
  const [selectedCat, setSelectedCat] = useState("Next Closest")
  const init = useRef(true)

  const handleGetEvents = async () => {
    const selectedEventValue = searchParams.has("event")
      ? categories.find((cat) => cat.label === searchParams.get("event")).value
      : categories[0].value
    try {
      const { data } = await ClientApiList.getEventList(
        selectedEventValue,
        currentPage
      )
      console.log(data, 'data indo')
      setPageTitle(data.data.title)
      if (!searchParams.has("event-page"))
        setListEvents([...data.data.data.items])
      else setListEvents((prev) => [...prev, ...data.data.data.items])
      setPagingData(data.data.data.paging)
      setIsLoadMore(false)
      setIsLoading(false)
    } catch (err) {
      console.log("[Error:]", err)
    }
    init.current = false
  }

  useEffect(() => {
    handleGetEvents()
    if (searchParams.has("event")) setSelectedCat(searchParams.get("event"))
  }, [searchParams])

  return (
    <div className="p-[1px] bg-cta-4 w-full lg:rounded-[15px]">
      <div
        className={cn(
          "bg-bg-3 relative z-[2] w-full px-[9px] pt-[21px] lg:p-[0] lg:pt-[54px] lg:pl-[34px] lg:pr-[39px] lg:rounded-[14px] lg:max-h-[609px] lg:min-h-[609px] lg:overflow-auto",
          Styles.container
        )}
      >
        <div className="max-w-[650px] mx-auto text-[20px] text-text-2 font-medium font-font-family-6 mb-[10px] leading-[27px] pl-[5px] md:p-0">
          {pageTitle}
        </div>
        <EventCategories
          {...{
            categories,
            setIsLoading,
            selectedCat,
          }}
        />
        <Suspense fallback={<p>Loading...</p>}>
          <EventList
            isLoadMore={isLoadMore}
            isLoading={isLoading}
            items={listEvents}
            selectedCat={selectedCat}
          />
        </Suspense>
        {pagingData.nextPage !== null && !isLoadMore ? (
          <LoadMoreBtn
            setIsLoadMore={setIsLoadMore}
            cat="event"
            load_more_btn_label={load_more_btn_label}
          />
        ) : null}
      </div>
    </div>
  )
}

export function EventListCalendar(props) {
  return (
    <Suspense fallback={<EventListCalendarFallback />}>
      <SuspendedEventListCalendar {...props} />
    </Suspense>
  )
}

SuspendedEventListCalendar.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  load_more_btn_label: PropTypes.string,
}
